@import "application/variables";
@import "application/icons";
@import "application/mixins";
@import "bootstrap";

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  font-weight: 100 1000;
  font-display: block;
}

:root {
  --halo-blue: #009cea;
}

html {
  overflow-x: hidden;
  position: relative;
}

html,
body {
  position: relative;
  font-family: "Inter", sans-serif;
  * {
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

textarea:focus,
input:focus {
  outline: none;
  border-color: var(--halo-blue);
}

.prelaunch-btn {
  width: 185px;
}

.btn.prelaunch-btn-mobile {
  width: 120px;
  font-size: 14px;
}

.prelaunch-btn:hover {
  transform: scale(1.1);
}

.white-text {
  color: white;
  &:hover {
    color: white;
  }
}

.no-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

a.disabled {
  cursor: default;
  pointer-events: none;
}

// Header
.main-header {
  .navbar-brand {
    padding: (($navbar-height - 15px)/2) 15px; // logo height=35px
    line-height: 50px;
  }
  .navbar-nav {
    & > li {
      height: $navbar-height;
    }
  }
  .navbar-nav > li > a {
    @include main-nav;
  }
  .navbar-nav > li > .dropdown-menu {
    margin-top: -10px;
  }
  .navbar-text-link {
    font-size: 14px;
    margin-left: 10px !important;
  }
  .navbar-button {
    background-color: #7d3ef4;
    align-items: center;
    display: flex;
    margin-top: 28px;
    margin-left: 40px;
    color: white;
    text-decoration: none;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 35px;
    max-width: 128px;
  }
  .navbar-button:hover {
    transform: scale(1.1);
    background-color: #7d3ef4;
  }
  .navbar-header {
    width: 100%;
  }
}

.alert {
  position: absolute;
  width: 100%;
  z-index: 10000;
  opacity: 0.9;
}

.pages-home main .alert {
  top: 10px;
  opacity: 0.9;
}

.pages-home .navbar .navbar-collapse.collapse.in,
.pages-home .navbar .navbar-collapse.collapsing {
  background: $purple;
}
.pages-home .navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: $purple;
}
.pages-home .navbar-collapse {
  box-shadow: none;
}
.pages-home .navbar-inverse .navbar-toggle:hover,
.pages-home .navbar-inverse .navbar-toggle:focus {
  background-color: $purple;
}
.pages-home .navbar-inverse .navbar-toggle {
  border: 1px solid $white;
}

// Footer
.footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: -10px;
  background-color: $navbar-inverse-bg;
  color: $white;

  .footer-contentwrap {
    display: flex;
    flex-direction: column;
    padding: 55px 0;
    margin: auto;
  }

  .container {
    padding-right: 15px;
  }

  .footer-upper {
    width: 100%;
    margin-bottom: 8rem;
  }

  .icon {
    height: 24px;
    width: 24px;
  }

  .footer-lower {
    display: flex;
    justify-content: space-between;
  }

  .footer-lower-right {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: $white;
    width: 42rem;

    a,
    span {
      line-height: 32px;
    }
  }

  a {
    color: $white;
  }

  section {
    min-width: 170px;
    margin-right: 2rem;
    text-align: left;
    color: $white;
    float: left;

    &:last-of-type {
      float: right;
    }

    &.footer-social-section {
      min-width: unset;
      width: 114px;
      margin-right: 0;
    }

    h3 {
      font-size: 14px;
      color: $white;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      padding-bottom: 10px;
      margin-top: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.footer-social-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .icon-linkedin-white {
      position: relative;
      bottom: 2px;
      left: 4px;
    }

    li {
      font-size: 14px;
      line-height: 32px;
      display: inline;

      a {
        color: $white;
      }
    }
  }
}

// Typography
main {
  letter-spacing: 0.01em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.primary-heading {
  margin-top: 0;
  margin-bottom: 32px;
  a,
  strong {
    color: $magenta;
  }
  &.dotted {
    @include dotted;
  }
}
.sub-heading {
  margin-top: 0;
  margin-bottom: 32px;
  a,
  strong {
    color: $magenta;
  }
  &.dotted {
    @include dotted;
  }
}
.font-small {
  font-size: 0.7em;
}
.font-weight-bold {
  font-weight: 600;
}
h1 {
  font-weight: 600;
}
h2,
h3 {
  font-weight: 300;
  margin-bottom: 1em;
}

h4 {
  font-weight: 400;
  margin-bottom: 0.6em;
  margin-top: 1.4em;
}
strong,
b {
  font-weight: 600;
}

// Buttons
.btn {
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 12px 30px;
  @include boxshadow;
}
.btn-secondary {
  background-color: $pink;
}

a.button-primary {
  padding: 16px 30px;
}

.button-primary {
  margin-top: 24px;
  display: block;
  margin: auto;
  position: relative;
  z-index: 1;
  height: 48px;
  background: var(--halo-blue);
  border: 1px solid var(--halo-blue);
  border-radius: 4px;
  padding: 8px 30px;
  color: #ffffff;
  line-height: 15px;
  font-weight: 500;
  font-size: 16px;
  outline: 0;
  transition: opacity 0.25s linear;
  text-decoration: none;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #009cea;
    border: 1px solid #009cea;
    border-radius: 4px;
    z-index: -1;
    transition: opacity 0.25s linear;
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 1;
      border-radius: 4px;
    }
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    text-decoration: none;
    color: #ffffff;
  }

  &.disabled {
    &.disabled-clickable {
      opacity: 0.45 !important;
      cursor: pointer !important;
      pointer-events: all !important;

      &:hover {
        opacity: 1 !important;
      }
    }
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.45;
  }
}

// Logo
.logo-halo {
  font-size: $nav-tabs-font-size;
  img {
    width: 6em;
  }
}

// Social Share
.social-share {
  font-size: 1.5em;
  padding: 0.5em 0.5em 0 0;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:last-of-type {
    padding-right: 0;
  }
}
.btn-facebook-share {
  background-color: #0369b2;
  a {
    color: #fff;
    font-size: inherit;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .facebook-logo {
    margin-right: 0.3rem;
  }
}
.icon-facebook {
  color: #0369b2;
}
.icon-twitter {
  color: #00bfd7;
}

// Wells
.well {
  background: #fff;
  border-radius: 0;
  h2,
  h3 {
    margin-top: 0;
    font-size: 1.5em;
  }
  img {
    max-width: 100%;
  }
  .btn {
    width: 100%;
  }
  .button_to {
    display: inline-block;
  }
  .list-unstyled li:last-of-type {
    margin-bottom: 0;
  }
}
.well-wrapper {
  @include boxshadow;
}

// Forms
.simple_form {
  margin-bottom: 2em;
  .form-group.boolean,
  .form-group.check_boxes {
    line-height: 1.2em;
  }
  .form-control {
    padding: 7px 14px;
  }
  .form-group .help-block {
    text-align: left;
  }
}

// arrowbox
.arrowbox {
  position: relative;
  padding: 0.5em 1em;
  border-radius: $border-radius-base;
  background-color: $gray;
  &:after {
    content: "";
    width: 0;
    position: absolute;
    right: -9px;
    top: 13px;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 14px solid $gray;
  }
}

// Session modals
#sign-up .btn {
  width: 75%;
  margin: 0.5em 0;
}
#sign-in .btn-fb,
#sign-up-via-email form {
  margin-top: 0.5em;
}

// Tabs
.nav-tabs {
  border-bottom: 1px solid $gray;
  text-transform: uppercase;
  font-weight: 600;
  font-size: $nav-tabs-font-size;
  .active {
    border-bottom: 5px solid $blue;
  }
  & > li > a {
    color: $nav-tabs-active-link-hover-color;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

// Modals
.modal {
  text-align: center;
  .simple_form {
    margin: 0;
  }
  .btn {
    margin-top: 0;
    font-size: 0.9em;
  }
  .vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
  .vertical-align-center {
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
  }
  abbr {
    display: none;
  }
}

// Overviews (used to display campaigns and disease categories)
.overviews {
  margin-top: 2em;
  border-top: 1px solid #d9e0e2;
}
.overview {
  @include boxshadow;
  background: #fff;
  padding: 0;
  .padded {
    padding: 20px;
  }
  h3 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.4em;
  }
  img {
    width: 100%;
  }
  p {
    @extend .font-small;
    margin-bottom: 0;
    strong {
      color: #000;
    }
  }
}

// Utilities
iframe {
  max-width: 100%;
}
.avatar {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: $magenta;
  color: #fff;
  text-align: center;
  line-height: 20px;
  margin-right: 1em;
}
.list-unstyled li {
  margin-bottom: 2em;
}
.link-unstyled {
  color: $text-color;
  text-decoration: none !important;
  &:hover,
  &:active {
    color: $text-color;
  }
  &.block-link-unstyled {
    display: block;
  }
}
.padded {
  padding: $padding-base-horizontal;
}
.top-padded {
  padding-top: 2em;
}
.halo-switch {
  width: 50%;
  margin: 0 auto 3em auto;
}
.help-block {
  color: $text-gray-color;
  font-size: 0.8em;
}
.popover-content {
  font-size: 0.8em;
}
.full-width {
  width: 100%;
}
.main-image {
  max-width: 100%;
}
.css-table {
  display: table;
  .css-table-row {
    display: table-row;
  }
  .css-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}
.no-break {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}
.absolute-center {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// Media Queries

// Small devices in portrait mood
@media screen and (max-width: 375px) and (orientation: portrait) {
  .modal {
    .modal-content {
      width: 100%;
    }
    .btn {
      font-size: 0.8em;
    }
  }
}

// applies to 1200px and under
@media (max-width: $container-large-desktop) {
  .footer {
    // padding: 55px 36px;

    .footer-upper {
      width: 100%;
    }
  }
}

// Small devices (tablets, 768px and lower)
@media (max-width: $screen-sm-min) {
  h2 {
    font-size: 1.6em;
  }
  h3 {
    font-size: 1.4em;
    line-height: 1.25;
  }
  h4 {
    font-size: 1.2em;
  }
  .btn {
    font-size: 1em;
    white-space: normal;
  }
  .nav-tabs {
    li {
      a {
        margin-right: 0;
        padding: 10px 12px;
      }
    }
  }
  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;

    .footer-contentwrap {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      padding: 26px 0;
      width: 90%;
    }

    .icon {
      height: 16px;
      width: 16px;
    }
    section,
    .footer-lower-right {
      display: none;
    }

    .footer-upper {
      margin: auto 0;
    }

    .logo {
      font-size: 10px;
    }

    section.footer-social-section {
      margin-right: 7rem;
      width: 75px;
      display: block;
      h3 {
        display: none;
      }
    }

    .footer-social-links {
      margin: 0;

      li {
        height: auto;
      }

      .icon-linkedin-white {
        left: 3px;
      }

      .icon-facebook-white {
        bottom: 1px;
        left: 1px;
        position: relative;
      }
    }
  }

  div.company-page-container {
    margin-bottom: 9rem !important;
  }

  .navbar-header {
    width: 100%;
    .navbar-brand {
      img {
        height: 50%;
      }
    }
  }
  .nav-tabs.mobile-stack > li {
    display: block;
    float: none;
    &.active {
      border-bottom: 0;
      border-left: 5px solid var(--halo-blue);
    }
  }
  .modal-dialog {
    .btn {
      width: 100%;
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .help-block {
      margin-bottom: 1em;
    }
  }
}
@media (min-width: $screen-sm-min) {
  .navbar-nav > li > .sign-up {
    border: 1px solid #fff;
    border-radius: 3px;
    margin-top: 16px;
    padding: 5px 26px;
    margin-left: 1em;
    font-weight: 600;
  }
  .navbar-right {
    margin-right: 0;
  }
  .divider {
    background: transparent left center url("../images/home/divider-bg.png") no-repeat;
  }
}
@media (min-width: $screen-lg) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: $screen-sm) {
  .navbar-button {
    top: -10px;
    right: 20px;
    position: absolute;
    display: block;
  }
  .navbar {
    min-height: 0px;
    text-align: center;
    margin-bottom: 20px;
    &-header {
      margin: 0px auto;
      .navbar-brand {
        padding: (($navbar-mobile-height - 25px)/2) 15px;
        line-height: $line-height-base;
      }
    }
    &-toggle {
      margin: (($navbar-mobile-height - 34px)/2) 15px;
    }
    &-brand {
      height: $navbar-mobile-height;
      img {
        height: $logo-image-height-mobile !important;
        width: auto;
      }
    }
  }
}

.ui.transparent.icon.input > i.icon {
  width: 50px;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5);
}

.ui.dropdown:not(.button) > .default.text {
  color: #babec9;
}

input::placeholder {
  color: #babec9;
}

textarea::placeholder {
  color: #babec9;
}

.ui.modal {
  padding: 2.5em;
}

.ui.fluid.dropdown > .dropdown.icon {
  margin-top: -4px;
}

.ui.selection.dropdown > .search.icon {
  margin-top: -9px;
  padding-right: 8px;
}

.ui.selection.dropdown > .angle.down.icon {
  position: absolute;
  right: 7px;
  color: #464648;
}

.ui.selection.dropdown > .chevron.down.icon {
  font-size: 12px;
  position: absolute;
  right: 7px;
}

.ui.toggle + .ui.dropdown > .chevron.down {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.ui.search.dropdown > input.search {
  height: 100%;
}

.onboarding-dropdown {
  .ui.search.dropdown > input.search {
    height: auto;
    font-size: 14px;
    padding-top: 14px;
  }
}

.onboarding-dropdown {
  .ui.multiple.search.dropdown > input.search {
    height: auto;
    font-size: 14px;
    padding-top: 0px;
  }
}

.large-dropdown {
  .ui.fluid.dropdown > .dropdown.icon {
    margin-top: -10px;
  }

  .ui.search.dropdown > input.search {
    height: 38px;
    font-size: 14px;
  }
}

.custom-dropdown {
  .search {
    height: 48px;
  }
}
